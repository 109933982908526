.App {
  min-height: 100vh;
}

.App--solving {
  background-color: rgba(0, 0, 0, 0.16);
}

.content {
  padding: 0 1rem;
}

.character.card .content {
  padding: 0.4em !important;
}

.character.card .characterContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.character.card .questionFeedback {
  position: absolute;
}

.character.card .characterName {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 2px;
  text-align: center;
  width: 100%;
}

.character.card .characterName--small {
  font-size: 66%;
  padding: 0;
}

.character--eliminated {
  opacity: 0.12;
}

.playerDetail,
.opponentDetail {
  padding: 0.4em !important;
}

.playerDetail .character.card {
  margin-bottom: 0 !important;
}

/* slick carousel */
#SelectCharacterModal .sliderContent {
  padding: 0 4em !important;
}

.selectCharacterCarousel .slick-slide {
  opacity: 0.2;
  position: relative;
  z-index: 0;
}

.selectCharacterCarousel .slick-list {
  padding: 2em 0 3em 0 !important;
}

.selectCharacterCarousel .slick-current {
  opacity: 1;
  z-index: 999 !important;
}

@media screen and (min-width: 768px) {
  .selectCharacterCarousel .slick-current {
    transform: scale(1.2);
  }
}

.slickArrowContainer {
  display: flex !important;
  align-items: center;
}

.slickArrowContainer.slickArrowContainer--prev {
  justify-content: flex-start;
  padding-left: 0 !important;
}

.slickArrowContainer.slickArrowContainer--next {
  justify-content: flex-end;
  padding-right: 0 !important;
}
/* end slick carousel */
